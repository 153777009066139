@import "../../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//header {
//  background-color: #333;
//
//  h1 {
//    font-size: 1.2em;
//    color: #f0f0f0;
//    padding: 1em 5em;
//  }
//}
